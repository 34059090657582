* {
    box-sizing: border-box;
}

.all-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.contenedor-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    color: #151F47;
}

.contenedor-pequeno {
    width: 25%;
    padding: 5px;
}

.contenedor-mediano {
    width: 50%;
    padding: 5px;
}

.contenedor-pasos {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
}

.contenedor-pasos .titulo {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DF5E2D;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
}

.contenedor-pasos .contenido {
    padding: 10px;
    height: 250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.contenedor-pasos .contenido .icono-carga {
    width: 100%;
    height: 140px;
    background-color: #f4f4f4;
    border: dashed 1px #b4b4b4;
    color: #151f47;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.contenedor-pasos .contenido p {
    text-align: center;
}

.contenedor-pasos .contenido .icono-carga div {
    text-align: center;
}

.contenedor-pasos .contenido .icono-carga img {
    width: 40px;
    margin: 0 auto;
}

.contenedor-pasos .contenido .icono-carga p {
    width: 100%;
    margin-top: 14px;
    margin-bottom: 0px;
}

.contenedor-pasos .contenido .form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.contenedor-pasos .contenido .form div {
    width: 50%;
}

.contenedor-pasos .contenido .form div:first-child {
    text-align: right;
    padding-right: 15px;
}

.contenedor-pasos .contenido .form div:nth-child(2) {
    color: #DF5E2D;
}

.contenedor-pasos .contenido .form .control {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contenedor-pasos .contenido .form .control div {
    width: 100%;
}

.table-salidas {
    border-radius: 16px;
    border-collapse: collapse;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
    table-layout: fixed;
}

.table-salidas .flex {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.table-salidas thead {
    background-color: #3061aa;
    color: #fff;
}

.table-salidas th, .table-salidas td {
    padding: 5px;
    text-align: center;
}

.table-salidas td:first-child span {
    margin-right: 5px;
}

.table-salidas tbody tr:nth-child(odd) {
    background: #fff;
}

.table-salidas tbody tr:nth-child(even) {
    background: #f4f4f4;
}

.contenedor-botones {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mensaje-error {
    display: 'flex';
    color: '#3061AA';
}

.table-reporte {
    border-radius: 16px;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0px 3px 6px #ccc;
    margin: 20px 0px;
    width: 100%;
    table-layout: fixed;
}

.table-reporte thead {
    background-color: #f4f4f4;
}

.table-reporte thead tr th:first-child {
    text-align: left;
    padding: 20px;
}

.table-reporte thead tr th:last-child {
    text-align: right;
    padding: 20px;
}

.table-reporte td {
    
    padding: 5px;
    text-align: center;
}

.table-reporte td:first-child span {
    margin-right: 5px;
}

.table-reporte tbody tr:nth-child(odd) {
    background: #fff;
}

.table-reporte tbody tr:nth-child(even) {
    background: #f4f4f4;
}

.table-reporte tbody tr:first-child {
    background-color: #3061aa;
    color: #fff;
}

.table-reporte tbody tr:first-child td {
    padding: 10px 0;
}

.empleados {
    margin-bottom: 20px;
}

.empleados h3 {
    text-align: center;
}

.empleados div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-reporte-detalle {
    border-radius: 16px;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0px 3px 6px #ccc;
    margin: 20px 0px;
    width: 100%;
    table-layout: fixed;
}

.table-reporte-detalle thead {
    background-color: #7E7E7E;
    color: #fff;
}

.table-reporte-detalle thead tr th {
    padding: 10px;
    font-weight: normal;
}

.table-reporte-detalle thead p {
    margin: 0;
}

.table-reporte-detalle td {
    
    padding: 5px;
    text-align: center;
}

.table-reporte-detalle td:first-child span {
    margin-right: 5px;
}

.table-reporte-detalle tbody tr:nth-child(odd) {
    background: #fff;
}

.table-reporte-detalle tbody tr:nth-child(even) {
    background: #f4f4f4;
}

.table-reporte-detalle tbody tr:nth-child(1) td {
    text-align: center;
    padding: 15px;
}

.table-reporte-detalle tbody tr:nth-child(2) td:first-child {
    text-align: left;
    font-weight: 600;
}

.table-reporte-detalle tbody tr:nth-child(2) td:last-child {
    text-align: right;
}

.table-reporte-detalle tbody tr:nth-child(3) {
    background-color: #3061aa;
    color: #fff;
}

.table-reporte-detalle tbody tr:nth-child(3) td {
    padding: 10px 0;
}

.div-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedor-modal {
    max-height: 400px;
    overflow: hidden;
}

.contenedor-modal .contenido-modal {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

p.informacion{
    font-weight:normal;
    font-size:16px;
    text-align:justify;
    color:#3061AA;
}

p.error{
    font-weight:normal;
    font-size:16px;
    text-align:justify;
    color:#DF5E2D;
}

div.item-cambiar-cliente{
    margin-top:20px;
    color:#151f47;
    font-weight: bold;
    cursor: pointer;
}

label.radio-opcion span.MuiTypography-root{
    font-family: 'Montserrat';
    font-size:14px;
    color:#151F47;
}

.text-red {
    color: red;
}