header {
    background-color: white;
    display: flex;
    align-items: stretch;
    border-bottom: #162044 solid 5px;


}

header>div {
    background-color: white;
    margin: 10px;
}

header>div.logo{
    width:30%;
    align-self: center;
}

header>div.titulo-plataforma{
    text-align: center;
    color: #DF5E2D;
    width:40%;
    font-size: 20px;
    font-weight: bold;
    margin-top:24px;
}

header>div.datos-usuario{
    text-align: right;
    width:30%;
    align-self: center;
    padding-right:20px;
}


header .usuario{
    color:#151F47;
    margin-right: 38px;
    padding-top: 3px;
}

header .btn-cerrar{
    float: right;
}

.nombre-cliente{
    color: #DF5E2D;
    font-size: 22px;
    margin-top:14px;
    font-weight: bold;
    
}

.nombre-cliente-enc{
    color: #DF5E2D;
    font-size: 14px;
    margin-top:14px;
    font-weight: bold;
    
}

header>div>div.sub-titulo-plataforma{
    width: 100%;
    text-align: center;
    color: #151F47;
    font-size: 14px;
    font-weight: bold;
}