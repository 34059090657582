.card {
  width: 100%;
  border-radius: 20px;
  /*border: 1px solid gray;*/
  padding: 4px;
  background-color: white;
  padding: 10px;
  padding-bottom:30px;
  box-shadow: 0px 3px 6px #00000029;
}

.todo-el-ancho{
  margin:20px;
}

.derecha{
  margin:20px 20px 20px 0px;
  width:100%;
}

.card >h1{
  color:#151F47;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
}

.card >p{
  color:#7E7E7E;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}