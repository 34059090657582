.form{
    width:420px;
    display: flex;
    flex-direction: column;
    margin:auto;
}
/*si se pone en un div a los controles */
.form>div{
    display: flex;
    flex-direction: column;
    width:100%;
}

.form .control{
    margin-top:20px;
}

.form .control-m0{
    margin-top:0px;
}

label.Mui-focused{
    color:#151F47;
}

.form .label{
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    /*margin-left:10px;*/
    color:#151F47;
    font-weight:bold;
}

.form .label .Mui-focused {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    /*margin-left:10px;*/
    color:#151F47;
    font-weight:bold;
}

.form .input-label{
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    /*margin-left:10px;*/
    color:#151F47;
    font-weight:bold;
}

.form .input-label.Mui-focused {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    /*margin-left:10px;*/
    color:#151F47;
    font-weight:bold;
}

.form .btn-orange{
    color:white;
    background-color: #DF5E2D;
}

li.menuItem{
    font-family: 'Montserrat', sans-serif;
    color:#151F47;
}