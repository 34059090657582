.menu {
    margin: 20px 20px 20px 20px;
    width: 240px;
    text-align: center;
    padding:10px 0px;
}

.menu h1{
    color:#DF5E2D;
    margin:0px 10px;
    font-size: 15px;

}
