.itemHome {
    text-align:center;
    margin-top:32px;
    width:268px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    height:204px;
    justify-self: center;
    cursor:pointer
}

.itemHome:hover {
    box-shadow: 0px 3px 6px #777;

}

.itemHomeInterno1 {
    background: #3061AA;
    color: white;
    font-weight: bold;
    height: 141px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
}

.itemHomeInterno2 {
    background: #DF5E2D;
    color: white;
    font-weight: bold;
    height: 141px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
}

.itemHomeInterno3 {
    background: #151F47;
    color: white;
    font-weight: bold;
    height: 141px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
}

.itemHomeInterno1 div, .itemHomeInterno2 div, .itemHomeInterno3 div{
    padding-top:10px;
}

.itemHomeInterno1 img, .itemHomeInterno2 img, .itemHomeInterno3 img{
    margin-top:10px;
}

.itemHomeTexto{
    color:#151F47;
    font-size: 13px;
    margin-top: 8px;
    padding-left: 2px;
    padding-right: 2px;
}