.side-login{
    float: left;
    padding:20px 50px;
    width:50%;
    margin-top:50px;
}

.side-login h1{
    
    color:#DF5E2D;
    font-size: 22px;
    font-weight: bold;
}

.side-login h2{
    color:#162044;
    font-size: 22px;
    margin-top:40px;
    
}

.side-login .social{
    display: flex;
    width:50%;
    margin-left:80px;
}
.side-login a{
    flex-grow: 1;
}  

.side-login .info-home{
    display: flex;
    align-items: center;
}

.side-login__icons {
    
}

.side-login__info {
    font-size:14px;
    color:#151F47;
    margin-left: 32px;
}