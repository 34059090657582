* {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F4F4
}

.mobile-titulo-plataforma {
  display: none;
}

.mobile-sub-titulo-plataforma {
  display: none;
}

.parrafo {
  font-size: 14px;
  color: #7E7E7E;
  margin: 5px 0px 0px 0px;
  text-align: center;
}

input {
  font-family: 'Montserrat', sans-serif;
}

div.botones {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

div.botones button {
  margin-right: 8px;
}

button.btn-orange {
  color: white;
  background-color: #DF5E2D;
}

.tabla {

  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  max-width: 800px;
  width: 100%;
  margin: auto;

}

.tabla caption {
  background-color: #F4F4F4;
  color: #151F47;
  padding: 20px 3px 20px 10px;
  text-align: left;
  font-weight: bold;
  border-bottom-left-radius: 10px;
}

.tabla th {
  background-color: #3061AA;
  color: white;
  padding: 10px 10px 10px 10px;
  font-weight: normal;
  text-align: left;
}

.tabla td {
  padding: 3px;
  font-weight: normal;
  padding: 10px 10px 10px 10px;
}

.tabla tr:nth-child(even) {
  background-color: #F4F4F4;
}

/*se quita estilo por default cuando recuerda password  */
input:-webkit-autofill {
  -webkit-box-shadow: 200px 200px 100px white inset;
  box-shadow: 200px 200px 100px white inset;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1200px) {
  .side-login {
    display: none;
  }

  .login {
    width: 90% !important;
    margin: auto !important;
    margin-top: 30px !important;
  }

  .mobile-titulo-plataforma {
    display: block;
    color: #DF5E2D;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .mobile-sub-titulo-plataforma {
    width: 100%;
    text-align: center;
    color: #151F47;
    font-size: 14px;
    font-weight: bold;
  }

}