table.advertencias{
    width:100%;
    border-spacing: 0px;
}

table.advertencias td{
    font-size:14px;
    padding:2px;
    vertical-align: middle;
    color:#3061AA;
}

table.advertencias td:nth-child(1){
    width:18px;
}

table.advertencias td img{
    display: block;
    height: 14px;
}

table.advertencias tr:nth-child(odd) {
    background-color: #F4F4F4;
}
