table.errores{
    width:100%;
    border-spacing: 0px;
}

table.errores td{
    font-size:14px;
    padding:2px;
    vertical-align: middle;
    color:#3061AA;
}

table.errores td:nth-child(1){
    width:18px;
}

table.errores td img{
    display: block;
    height: 14px;
}

table.errores tr:nth-child(odd) {
    background-color: #F4F4F4;
}
